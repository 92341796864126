import { createGlobalStyle } from "styled-components"

export const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
}

const GlobalStyle = createGlobalStyle`
  a,
  p {
    font-family: Roboto;
    font-size: 20px;
    line-height: 32px;
    text-decoration: none;
    margin: 0;
  }

  a:hover {
    transition:all 100ms ease-in-out;
    color:#D42B69;
  }

  h2 {
    color: #1C1C26;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    @media screen and (min-width: ${breakpoints.md}px) {
      font-size: 24px;
      line-height: 34px;
    }
  }
`

export default GlobalStyle
