import styled, { css } from "styled-components"
import { breakpoints } from "./../assets/globalStyles"

const generateMediaQuery = ({ when }) => {
  if (!when) return

  // get array of breakpoint names used
  const breakpointsUsed = Object.keys(when)

  return breakpointsUsed.map(
    (breakpointName) => css`
      @media screen and (min-width: ${breakpoints[breakpointName]}px) {
        width: ${when[breakpointName]}px;
        min-width: ${when[breakpointName]}px;
        height: ${when[breakpointName]}px;
        min-height: ${when[breakpointName]}px;
      }
    `
  )
}

const Spacer = styled.span`
  //background-color: red;
  display: block;
  width: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  ${generateMediaQuery}
`

export default Spacer
