/* eslint no-useless-escape: 0 */

import React from "react"
import { Form } from "react-final-form"
import styled from "styled-components"
import fetchJsonp from "fetch-jsonp"
import { Link } from "gatsby"
import { useToasts } from "react-toast-notifications"

import { breakpoints } from "./../assets/globalStyles"
import Input from "./Input"
import Button from "./Button"
import Spacer from "./Spacer"

const StyledSignupForm = styled.form`
  .label-container label {
    color: #ffffff !important;
    opacity: ${({ context }) => (context === "footer" ? 0.5 : 1)};
  }

  .submit-area {
    @media screen and (min-width: ${breakpoints.sm}px) {
      display: flex;
      align-items: center;
    }

    p,
    a {
      color: rgba(255, 255, 255, 0.5) !important;
      font-family: Roboto;
      font-size: 16px;
      line-height: 24px;
    }

    a:hover {
      color: #d42b69 !important;
    }
  }
`

export default function NewsletterSignup({ context = "footer" }) {
  const { addToast } = useToasts()

  return (
    <Form
      onSubmit={(values) => {
        //Serialize the form data into a query string
        const serialize = (values) =>
          Object.keys(values)
            .map((key) => {
              const fieldName = key
              const fieldValue = values[key]

              return `&${encodeURIComponent(fieldName)}=${encodeURIComponent(
                fieldValue
              )}`
            })
            .join("")

        // Get the Submit URL
        let url =
          "https://daffodil-it.us3.list-manage.com/subscribe/post-json?u=43dcdd730251de86eb865dcc8&amp;id=1152433003"
        url += serialize(values)

        //post to this url
        return fetchJsonp(url, {
          jsonpCallback: "c",
        })
          .then((response) => response.json())
          .then(({ result, msg }) => {
            if (result === "error" && msg.includes("already subscribed")) {
              addToast("This email is already subscribed!", {
                appearance: "error",
              })
            } else if (result === "error") {
              addToast("Something went wrong, please try again.", {
                appearance: "error",
              })
            } else {
              addToast("Thank you for signing up!", {
                appearance: "success",
              })
            }
          })
          .catch(() =>
            addToast("Something went wrong, please try again.", {
              appearance: "error",
            })
          )
      }}
      render={({ handleSubmit, pristine, invalid }) => (
        <StyledSignupForm onSubmit={handleSubmit} noValidate context={context}>
          <Input
            name="EMAIL"
            id={context === "footer" ? "mce-EMAIL" : "mce-EMAIL-header"}
            type="email"
            required
            label="Email Address"
            fullwidth
          />
          <Input
            type="text"
            name="FNAME"
            id={context === "footer" ? "mce-FNAME" : "mce-FNAME-header"}
            required
            label="First Name"
            fullwidth
          />
          <Input
            type="text"
            name="b_43dcdd730251de86eb865dcc8_1152433003"
            tabIndex="-1"
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
            value=""
          />
          <div className="submit-area">
            <Button
              type="submit"
              disabled={pristine || invalid}
              id={
                context === "footer"
                  ? "mc-embedded-subscribe"
                  : "mc-embedded-subscribe-header"
              }
              colour="primary"
              variant="contained"
            >
              Subscribe
            </Button>
            <Spacer size={16} />
            <p>
              By clicking submit you are agreeing to our{` `}
              <Link to="/privacy-policy-cookies/">privacy policy</Link>.
            </p>
          </div>
        </StyledSignupForm>
      )}
    />
  )
}
