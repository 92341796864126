import React, { Fragment } from "react"
import styled, { keyframes } from "styled-components"
import { darken, rgba } from "polished"
import { breakpoints } from "./../assets/globalStyles"

const getColour = (colour) => {
  if (colour === "primary") {
    return "#d42b69"
  } else if (colour === "secondary") {
    return "#ffffff"
  }
}

const StyledButton = styled.button`
  background-color: ${({ disabled, loading, variant, colour = "primary" }) => {
    colour = getColour(colour)

    if (loading && variant !== "outline") {
      return darken(0.08, colour)
    }

    if (disabled && variant !== "outline") {
      return rgba(colour, 0.6)
    }

    if (disabled && variant === "outline") return rgba("#EEF1F4", 0.12)

    if (variant === "outline") return "transparent"

    return colour
  }};
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 150ms;
  position: relative;
  width: 100%;
  padding: 16px 24px;
  pointer-events: ${({ disabled, loading }) => {
    if (disabled || loading) return "none"

    return "auto"
  }};
  border: ${({ variant, colour = "primary" }) =>
    `solid 1px ${variant === "outline" ? getColour(colour) : "transparent"}`};
  border-radius: 4px;
  font-family: Poppins;
  color: ${({ variant, disabled, colour }) => {
    if (variant === "outline" && !disabled) {
      return getColour(colour)
    }

    if (variant === "outline" && disabled) return rgba("#1C1C26", 0.25)

    if (variant === "contained" && disabled) {
      return rgba("#FFFFFF", 0.6)
    }

    return colour === "primary" ? "#FFFFFF !important" : "#e51269 !important"
  }};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  @media screen and (min-width: ${breakpoints.md}px) {
    width: auto;
  }
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
    background-color: ${({ disabled, loading, variant, colour }) => {
      if (!disabled && !loading && variant === "outline")
        return rgba("#EEF1F4", 0.12)

      if (!disabled && !loading) {
        return darken(0.16, getColour(colour))
      }
    }};
  }
  &:hover {
    background-color: ${({ disabled, loading, variant, colour }) => {
      if (!disabled && !loading && variant === "outline")
        return rgba("#EEF1F4", 0.06)

      if (!disabled && !loading) {
        return darken(0.08, getColour(colour))
      }
    }};
    cursor: ${({ disabled, loading }) => {
      if (disabled || loading) return "auto"

      return "pointer"
    }};
  }
`

const HiddenButtonContent = styled.div`
  opacity: 0;
  display: flex;
`

const stretch = keyframes`
  0% {
    transform: scale(.5);
  }
  100% {
    transform: scale(1);
  }
`

const ButtonLoadingWrapper = styled.div`
  position: absolute;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    height: 8px;
    width: 8px;
    display: block;
    border-radius: 100%;
    animation-delay: 0.1s;
    margin-right: 5px;
    animation-direction: alternate;
    animation-duration: 0.5s;
    animation-fill-mode: none;
    animation-iteration-count: infinite;
    animation-name: ${stretch};
    animation-play-state: running;
    animation-timing-function: ease-out;
    background-color: ${({ variant, colour }) => {
      if (variant === "outline") {
        return getColour(colour)
      }

      return "#FFFFFF"
    }};
    &:first-child {
      animation-delay: 0s;
    }
    &:last-child {
      animation-delay: 0.2s;
      margin-right: 0;
    }
  }
`

const Button = ({ children, icon, loading, ...rest }) => (
  <StyledButton loading={loading ? 1 : 0} {...rest} label={children ? 1 : 0}>
    {loading ? (
      <Fragment>
        <HiddenButtonContent>
          {children}
          {icon && icon}
        </HiddenButtonContent>
        <ButtonLoadingWrapper
          variant={rest.variant}
          label={children ? 1 : 0}
          colour={rest.colour}
        >
          <span />
          <span />
          <span />
        </ButtonLoadingWrapper>
      </Fragment>
    ) : (
      <Fragment>
        {children}
        {icon && icon}
      </Fragment>
    )}
  </StyledButton>
)

export default Button
