import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, withArtDirection } from "gatsby-plugin-image"
import { useSpring, animated } from "react-spring"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import styled from "styled-components"

import { breakpoints } from "./../assets/globalStyles"
import Burger from "./Burger"
import DesktopNav from "./DesktopNav"

const HeaderWrapper = styled.header`
  padding: 0 16px;
  background-color: #d42b69;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: ${breakpoints.md}px) {
    height: 112px;
    padding: 0 48px;
    .mobile-menu-button {
      display: none;
    }
  }
  > a {
    display: inline-flex;
  }
`

const MobileNav = styled(animated.nav)`
  background-color: #d42b69;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 73px;
  z-index: 3;
  overflow: auto;
  padding: 40px 0;
  @media screen and (min-width: ${breakpoints.md}px) {
    display: none;
  }
  a:hover {
    color: white;
  }
`

const MobileNavItem = styled(Link)`
  display: block;
  padding: 8px 28px;
  border-left-color: ${({ active }) => (active ? "#ffffff" : "transparent")};
  border-left-width: 4px;
  border-left-style: solid;
  text-decoration: none;
  color: #ffffff;
  font-family: Poppins;
  font-size: 26px;
  line-height: 40px;
  font-weight: ${({ active }) => (active ? 600 : "")};
`

const StyledLogo = styled(GatsbyImage)`
  width: 32px !important;
  @media screen and (min-width: 940px) {
    width: 250px !important;
  }
`

export default function Header() {
  const {
    wpMenu: {
      menuItems: { nodes: menuItems },
    },
    wp: {
      options: {
        optionsPageFields: {
          logos: {
            lightLogo: {
              localFile: {
                childImageSharp: { desktopLogo, mobileLogo },
              },
            },
          },
        },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        wpMenu(locations: { eq: GATSBY_HEADER_MENU }) {
          menuItems {
            nodes {
              label
              url
            }
          }
        }
        wp {
          options {
            optionsPageFields {
              logos {
                lightLogo {
                  localFile {
                    childImageSharp {
                      mobileLogo: gatsbyImageData(
                        width: 36
                        height: 48
                        placeholder: NONE
                        layout: FIXED
                        quality: 90
                      )
                      desktopLogo: gatsbyImageData(
                        height: 32
                        placeholder: NONE
                        layout: FIXED
                        quality: 90
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const [showMenu, setShowMenu] = useState(false)

  // animation for opening sidebar
  const mobileMenuAnimation = useSpring({
    transform: showMenu ? "translateX(0%)" : "translateX(100%)",
  })

  //Lock body and scroll various elements inside the sidebar
  useEffect(() => {
    if (typeof document === "undefined") return

    if (showMenu) {
      disableBodyScroll(document.getElementById("mobileNav"))
    } else {
      enableBodyScroll(document.getElementById("mobileNav"))
    }
  }, [showMenu])

  return (
    <>
      <HeaderWrapper>
        <Link to="/">
          <StyledLogo
            image={withArtDirection(desktopLogo, [
              {
                media: `(max-width: 939px)`,
                image: mobileLogo,
              },
              {
                media: `(min-width: 940px)`,
                image: desktopLogo,
              },
            ])}
            objectPosition="left"
            alt="Daffodil Marketing Logo"
            objectFit="contain"
            loading="eager"
          />
        </Link>

        <Burger
          className="mobile-menu-button"
          onClick={() => {
            setShowMenu(!showMenu)
          }}
          isOpen={showMenu}
        />

        <DesktopNav menuItems={menuItems} />
      </HeaderWrapper>
      <MobileNav id="mobileNav" style={mobileMenuAnimation}>
        {menuItems.map(({ label, url }) => (
          <MobileNavItem
            key={url}
            to={url}
            active={
              typeof window !== "undefined" &&
              window.location.pathname.includes(url)
                ? 1
                : 0
            }
            onClick={() => {
              setShowMenu(false)
            }}
          >
            {label}
          </MobileNavItem>
        ))}
      </MobileNav>
    </>
  )
}
