/*eslint no-useless-escape: "off"*/
/*eslint jsx-a11y/label-has-associated-control: "off"*/
import React from "react"
import { useField } from "react-final-form"
import styled from "styled-components"

import Spacer from "./Spacer"

export const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  input,
  textarea {
    resize: none;
    width: 100%;
    max-width: ${({ fullwidth }) => {
      if (!fullwidth) return "330px"
    }};
    height: ${({ type }) => {
      if (type !== "textarea") return "40px"
      return "150px"
    }};
    border-width: 1px;
    border-style: solid;
    transition: all ease-in-out 150ms;
    border-color: ${({ error }) => {
      if (error) return "red"

      return "#CED4DB"
    }};
    border-radius: 4px;
    background-color: ${({ readOnly, disabled }) => {
      if (readOnly || disabled) return "#EEF1F4"

      return "#ffffff"
    }};
    padding: 8px 16px;
    font-family: Roboto;
    font-size: 16px;
    line-height: 26px;
    color: #66768e;
    margin-bottom: 28px;
    &:active,
    &:focus {
      border-color: ${({ readOnly, disabled }) => {
        if (!readOnly && !disabled) return "#F03A85"
      }};
      outline: none;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px #ffffff inset !important;
    }
    &[type="number"] {
      -moz-appearance: textfield;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .error-message {
    margin-top: -28px;
    color: red;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 28px;
  }
  .label-container {
    display: flex;
    align-items: center;
    label {
      color: #1c1c26;
      font-family: Roboto;
      font-size: 16px;
      line-height: 24px;
    }
  }
`

export default function Input({
  type,
  name,
  required,
  label,
  fullwidth,
  id,
  ...rest
}) {
  let validation

  switch (type) {
    case "email":
      validation = (value) => {
        if (!value && required) return "Required"
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!re.test(String(value).toLowerCase())) return "Invalid Email"
      }
      break

    case "text":
    default:
      validation = (value) => {
        if (!value && required) return "Required"
      }
      break
  }

  const { input, meta } = useField(name, {
    validate: validation,
  })

  let errorMessage

  if (meta.error && meta.touched) {
    errorMessage = meta.error
  } else if (!!meta.submitError && !meta.dirtySinceLastSubmit) {
    errorMessage = meta.submitError
  }

  return (
    <StyledInput
      {...rest}
      error={!!errorMessage ? 1 : 0}
      type={type}
      fullwidth={fullwidth ? 1 : 0}
    >
      <div className="label-container">
        <label htmlFor={id}>{label}</label>
        <Spacer size={8} />
        {!required && label && <label>(Optional)</label>}
      </div>

      <div className="input-container">
        {type === "textarea" ? (
          <textarea {...input} {...rest} id={id} />
        ) : (
          <input
            type={type}
            {...input}
            id={id}
            required={required}
            {...rest}
            row={9}
          />
        )}
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </StyledInput>
  )
}
