/*eslint no-useless-escape: "off"*/
/*eslint jsx-a11y/label-has-associated-control: "off"*/

import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { useTransition, animated } from "react-spring"

import CloseIcon from "./../assets/icons/close-icon.svg"
import CheckIcon from "./../assets/icons/check.svg"

import Spacer from "./Spacer"
import { breakpoints } from "../assets/globalStyles"

const shrinkKeyframes = keyframes`
  from {
    height: 100%;
  }
  to {
    height: 0%;
  }
`

const ToastWrapper = styled(animated.div)`
  position: relative;
  background-color: ${({ colour }) => colour};
  border-radius: 4px;
  margin: 0;
  max-width: calc(100vw - 16px);
  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 8px 8px 0 0;
  }

  img {
    margin: 24px 8px;
    filter: brightness(0) invert(1);
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
  }
`

const Countdown = styled.div`
  animation-duration: ${({ autoDismissTimeout }) => `${autoDismissTimeout}ms`};
  animation-name: ${shrinkKeyframes};
  animation-timing-function: linear;
  opacity: ${({ opacity }) => opacity};
  animation-play-state: ${({ isRunning }) =>
    isRunning ? "running" : "paused"};
  background-color: rgba(0, 0, 0, 0.08);
  bottom: 0;
  height: 0;
  left: 0;
  position: absolute;
  width: 100%;
`

const ToastContent = styled.div`
  background-color: white;
  padding: 24px;
  z-index: 1;
  width: 360px;
  border-color: #ced4db;
  border-width: 1px;
  border-style: solid;
  border-left: none;
  border-radius: 0px 4px 4px 0px;
  .title-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      color: #1c1c26;
      font-family: Poppins;
      font-size: 18px;
      line-height: 24px;
    }
  }
  p {
    color: #66768e;
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
  }
  img {
    cursor: pointer;
    margin: 0;
    filter: brightness(1) invert(0);
  }
`

const Toast = ({
  appearance,
  children,
  autoDismiss,
  autoDismissTimeout,
  isRunning,
  onMouseEnter,
  onMouseLeave,
  transitionState,
  transitionDuration,
}) => {
  //calaculate which kind of toast to show

  let icon
  let title
  let colour

  switch (appearance) {
    default:
    case "success":
      icon = <img src={CheckIcon} alt="" />
      title = "Success"
      colour = "green"
      break

    case "error":
      icon = <img src={CloseIcon} alt="" />
      title = "Error"
      colour = "red"
      break
  }

  const [showNotifications, setShowNotifications] = useState(true)

  useEffect(() => {
    if (transitionState === "exiting") {
      setShowNotifications(false)
    }
  }, [transitionState])

  const transition = useTransition(showNotifications, {
    config: { duration: transitionDuration },
    from: { right: -450, opacity: 0 },
    enter: { right: 1, opacity: 1 },
    leave: { right: -450, opacity: 0 },
  })

  return transition(
    (props, item) =>
      item && (
        <ToastWrapper
          colour={colour}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={props}
          data-testid="Toast"
        >
          <Countdown
            opacity={autoDismiss ? 1 : 0}
            autoDismissTimeout={autoDismissTimeout}
            isRunning={isRunning}
          />
          <div className="icon-wrapper">
            {icon}
            <ToastContent>
              <div className="title-bar">
                <h3>{title}</h3>
                <img src={CloseIcon} alt="" />
              </div>
              <Spacer size={8} />
              <p>{children}</p>
            </ToastContent>
          </div>
        </ToastWrapper>
      )
  )
}

export default Toast
