import React, { Fragment } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, withArtDirection } from "gatsby-plugin-image"
import styled from "styled-components"
import { transparentize } from "polished"

import NewsletterSignup from "./NewsletterSignup"
import ScrollToTop from "./ScrollToTop"
import Spacer from "./Spacer"
import { breakpoints } from "./../assets/globalStyles"

const FooterWrapper = styled.footer`
  background-color: ${transparentize(0.03, "#17172d")};
  padding: 24px 0;
  h5 {
    opacity: 0.5;
    color: #ffffff;
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
  }
  a,
  p {
    color: #ffffff;
  }

  a:hover {
    color: #d42b69;
  }
  .social-media-links {
    display: flex;
    @media screen and (min-width: ${breakpoints.sm}px) {
      flex-direction: column;
    }
  }
  hr {
    margin: 0;
    border-color: rgba(206, 212, 219, 0.25);
  }
  .legal-info-wrapper {
    @media screen and (min-width: ${breakpoints.lg}px) {
      display: flex;
      justify-content: space-between;
    }
    .legal-info {
      @media screen and (min-width: ${breakpoints.md}px) {
        display: flex;
        justify-content: center;
      }
      p,
      a {
        color: rgba(255, 255, 255, 0.5);
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        display: block;
        text-align: center;
      }

      a:hover {
        color: rgba(255, 255, 255, 1);
        opacity: 1;
      }
      span.divider {
        width: 1px;
        height: 16px;
        display: none;
        background-color: #ffffff;
        opacity: 0.5;
        @media screen and (min-width: ${breakpoints.md}px) {
          display: block;
          margin: 4px 8px 0;
        }
      }
    }
  }
`

const SocialMediaLink = styled.a`
  @media screen and (min-width: ${breakpoints.sm}px) {
    display: inline-flex;
    align-items: center;
  }

  div {
    display: none;
    @media screen and (min-width: ${breakpoints.sm}px) {
      display: block;
    }
  }
`

const SecondaryFooter = styled.div`
  background-color: #17172d;
  padding: 32px 0;
  .group-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      color: #ffffff;
      font-family: Roboto;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
    }
  }
  hr {
    margin: 0;
    border-color: rgba(206, 212, 219, 0.25);
  }
  .related-brands {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media screen and (min-width: ${breakpoints.lg}px) {
      flex-direction: row;
    }
  }
`

export default function Footer() {
  const {
    wp: {
      options: {
        optionsPageFields: {
          contactDetails: { address, email, phoneNumber },
          socialMediaLinks,
          newsletterPitch,
          logos: { relatedBrands },
        },
      },
    },
    groupLogo: {
      childImageSharp: { gatsbyImageData: groupLogo },
    },
  } = useStaticQuery(
    graphql`
      {
        wp {
          options {
            optionsPageFields {
              contactDetails {
                address
                email
                phoneNumber
              }
              newsletterPitch
              socialMediaLinks {
                link
                platformName
                icon {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 40
                        placeholder: TRACED_SVG
                        layout: FIXED
                        quality: 90
                      )
                    }
                  }
                }
              }
              logos {
                relatedBrands {
                  link
                  logo {
                    localFile {
                      childImageSharp {
                        tablet: gatsbyImageData(
                          height: 21
                          placeholder: TRACED_SVG
                          layout: FIXED
                          quality: 90
                        )
                        otherSizes: gatsbyImageData(
                          height: 32
                          placeholder: TRACED_SVG
                          layout: FIXED
                          quality: 90
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
        groupLogo: file(name: { eq: "group-white-text" }) {
          childImageSharp {
            gatsbyImageData(
              height: 32
              placeholder: TRACED_SVG
              layout: FIXED
              quality: 90
            )
          }
        }
      }
    `
  )

  return (
    <>
      <FooterWrapper>
        <ScrollToTop />
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-8 col-lg-4">
              <h5>Email:</h5>
              <a href={`mailto:${email}`}>{email}</a>
              <Spacer size={16} />
              <h5>Call:</h5>
              <a href={`tel: ${phoneNumber}`}>{phoneNumber}</a>
              <Spacer size={16} />
              <h5>Visit:</h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: `<p>${address}</p>`,
                }}
              />
              <Spacer size={16} />
            </div>
            <div className="col-12 col-sm-4 col-lg-3">
              <h5>Social Media:</h5>
              <Spacer size={16} />
              <div className="social-media-links">
                {socialMediaLinks.map(
                  ({
                    link,
                    platformName,
                    icon: {
                      localFile: {
                        childImageSharp: { gatsbyImageData: iconData },
                      },
                    },
                  }) => (
                    <Fragment key={platformName}>
                      <SocialMediaLink
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <GatsbyImage image={iconData} alt={platformName} />
                        <Spacer size={16} />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `${platformName}`,
                          }}
                        />
                      </SocialMediaLink>
                      <Spacer size={24} />
                    </Fragment>
                  )
                )}
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <h5>Newsletter:</h5>
              <Spacer
                size={0}
                when={{
                  lg: 16,
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: `<p>${newsletterPitch}</p>`,
                }}
              />
              <Spacer
                size={16}
                when={{
                  lg: 24,
                }}
              />
              <NewsletterSignup />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Spacer
                size={32}
                when={{
                  lg: 56,
                }}
              />
              <hr />
              <Spacer size={32} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 legal-info-wrapper">
              <div className="legal-info">
                <p>{`© Copyright ${new Date().getFullYear()} Daffodil Group Ltd`}</p>
                <Spacer size={8} when={{ md: 0 }} />
                <span className="divider"></span>
                <p>Company No: 8567514</p>
                <Spacer size={8} when={{ md: 0 }} />
              </div>
              <Spacer size={0} when={{ md: 8, lg: 0 }} />
              <div className="legal-info">
                <Link to="/terms-and-conditions/">Terms and Conditions</Link>
                <Spacer size={8} when={{ md: 0 }} />
                <span className="divider"></span>
                <Link to="/privacy-policy-cookies/">
                  Privacy Policy & Cookies
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Spacer size={8} />
      </FooterWrapper>
      <SecondaryFooter>
        <div className="container">
          <div className="row">
            <div className="col-12 group-logo">
              <GatsbyImage image={groupLogo} alt="Daffodil Group Logo" />
              <Spacer size={24} />
              <p>Daffodil Marketing is a proud part of the Daffodil Group</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Spacer size={32} />
              <hr />
              <Spacer size={32} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 related-brands">
              {relatedBrands.map(
                (
                  {
                    logo: {
                      localFile: {
                        childImageSharp: { tablet, otherSizes },
                      },
                    },
                    link,
                  },
                  index
                ) => (
                  <Fragment key={index}>
                    <a href={link} target="_blank" rel="noreferrer">
                      <GatsbyImage
                        image={withArtDirection(otherSizes, [
                          {
                            media: `(min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg}px)`,
                            image: tablet,
                          },
                        ])}
                        alt={link}
                      />
                    </a>
                    {index !== relatedBrands.length - 1 && (
                      <Spacer size={16} when={{ md: 24, lg: 32, xl: 56 }} />
                    )}
                  </Fragment>
                )
              )}
            </div>
          </div>
        </div>
      </SecondaryFooter>
    </>
  )
}
