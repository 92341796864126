import React, { useEffect } from "react"
import { ToastProvider } from "react-toast-notifications"

import "reset-css"
import "bootstrap-4-grid"
import "typeface-roboto"
import "typeface-poppins"
import "slick-carousel/slick/slick.css"

import Header from "./Header"
import Footer from "./Footer"
import CookieWarning from "./CookieWarning"
import FacebookChat from "./FacebookChat"
import GlobalStyle from "../assets/globalStyles"
import Toast from "./Toast"

export default function Layout({ children }) {
  // Create console log for devs viewing the console, use effect so it only runs on first mount
  useEffect(() => {
    const titleStyle = [
      "padding:8px",
      "border-radius: 4px",
      "background-color:#d42b69",
      "font-family: Arial",
      "color: white",
      "font-size:18px",
      "margin-bottom:8px",
    ].join(";")
    const textStyle = ["font-family: Arial", "font-size:16px"].join(";")

    //emojis
    const eyes = String.fromCodePoint(0x1f440)
    const hands = String.fromCodePoint(0x1f44f)
    const search = String.fromCodePoint(0x1f50e)
    const smile = String.fromCodePoint(0x1f604)
    console.log(
      `%cLooking for something? ${eyes} %c \nNot many people care about what's under the hood of a website, we like people who do. ${hands} \n\nIf you're interested in joining Daffodil as part of our web development team, send an email to vacancies@daffodil-group.co.uk and let us know you found this message. ${search} \n\nBonus points if you can tell us what this website is built with! ${smile}`,
      titleStyle,
      textStyle
    )
  }, [])

  return (
    <ToastProvider
      components={{ Toast: Toast }}
      autoDismissTimeout={6000}
      autoDismiss
      transitionDuration={300}
    >
      <GlobalStyle />
      <Header />
      {children}
      <FacebookChat />
      <Footer />
      <CookieWarning />
    </ToastProvider>
  )
}
