/*eslint react-hooks/exhaustive-deps: "off"*/

import React, { useState, useEffect } from "react"
import Cookies from "universal-cookie"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { useSpring, animated, useTransition } from "react-spring"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { useLocation } from "@reach/router"

import Button from "./Button"
import Spacer from "./Spacer"
import { breakpoints } from "../assets/globalStyles"

const StyledCookieWarning = styled.div`
  background-color: #17172d;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px 0;
  z-index: 2;
  @media screen and (min-width: ${breakpoints.lg}px) {
    padding: 32px 0;
  }
  .cookie-content {
    @media screen and (min-width: ${breakpoints.lg}px) {
      display: flex;
      align-items: center;
    }
    .mobile-cookie {
      @media screen and (min-width: ${breakpoints.lg}px) {
        display: none !important;
      }
    }
    .desktop-cookie {
      display: none !important;
      @media screen and (min-width: ${breakpoints.lg}px) {
        display: inline-block !important;
      }
    }
    .cookie-title {
      display: flex;
      align-items: center;
      h2 {
        font-family: Roboto;
        font-size: 18px;
        line-height: 28px;
        color: white;
      }
    }
    p {
      color: #ffffff;
      font-family: Roboto;
      font-size: 16px;
      line-height: 24px;
    }

    a {
      color: #ffffff;
      font-family: Roboto;
      font-size: 16px;
      line-height: 24px;
      &:hover {
        color: #d42b69;
      }
    }

    button {
      white-space: nowrap;
      position: relative;
      @media screen and (min-width: ${breakpoints.lg}px) {
        margin-left: auto;
        &:hover {
          border-color: #4488f3;
          color: #4488f3;
          transition: all 150ms linear;
        }
      }

      .eyes-wrapper {
        display: none;
        @media screen and (min-width: ${breakpoints.lg}px) {
          display: block;
          position: absolute;
          right: 58px;
          bottom: 23px;
        }
      }
    }
  }
`

export default function CookieWarning() {
  const [showCookieBanner, setShowCookieBanner] = useState(false)
  const [showEyes, setShowEyes] = useState(false)

  const location = useLocation()

  const cookies = new Cookies()

  useEffect(() => {
    if (
      !cookies.get("gatsby-gdpr-google-analytics") ||
      !cookies.get("gatsby-gdpr-facebook-pixel") ||
      !cookies.get("gatsby-gdpr-google-tagmanager")
    ) {
      setShowCookieBanner(true)
    }
  }, [cookies])

  const eyesAnimation = useSpring({
    opacity: showEyes ? "1" : "0",
  })

  const AnimatedEyes = animated(GatsbyImage)

  const transition = useTransition(showCookieBanner, {
    from: { transform: "translateY(100%)" },
    enter: { transform: "translateY(0%)" },
    leave: { transform: "translateY(100%)" },
  })

  const AnimatedCookieBar = animated(StyledCookieWarning)

  const {
    wpPage: { slug: cookiePageLink },
    cookieImage: {
      childImageSharp: { cookieImage },
    },
    eyeImage: {
      childImageSharp: { eyeImage },
    },
  } = useStaticQuery(
    graphql`
      {
        wpPage(title: { eq: "Privacy Policy & Cookies" }) {
          slug
        }
        cookieImage: file(name: { eq: "cookie" }) {
          childImageSharp {
            cookieImage: gatsbyImageData(
              width: 40
              layout: FIXED
              placeholder: NONE
              quality: 90
            )
          }
        }
        eyeImage: file(name: { eq: "eyes" }) {
          childImageSharp {
            eyeImage: gatsbyImageData(
              width: 25
              layout: FIXED
              placeholder: NONE
              quality: 90
            )
          }
        }
      }
    `
  )

  return transition(
    (props, item) =>
      item && (
        <AnimatedCookieBar style={props}>
          <div className="container">
            <div className="row">
              <div className="col-12 cookie-content">
                <GatsbyImage
                  image={cookieImage}
                  alt="Cookie Image"
                  objectFit="contain"
                  className="desktop-cookie"
                />
                <Spacer size={0} when={{ lg: 24 }} />
                <div>
                  <div className="cookie-title">
                    <GatsbyImage
                      image={cookieImage}
                      alt="Cookie Image"
                      objectFit="contain"
                      className="mobile-cookie"
                    />
                    <Spacer size={8} when={{ lg: 0 }} />
                    <h2>Our website uses cookies</h2>
                  </div>
                  <Spacer size={24} when={{ lg: 0 }} />
                  <p>
                    This website uses cookies to make your experience the best
                    possible, To read more about their use, read our{" "}
                    <Link to={`${cookiePageLink}/`}>cookie policy</Link>.
                  </p>
                </div>
                <Spacer size={24} />
                <Button
                  type="button"
                  variant="outline"
                  colour="secondary"
                  onMouseEnter={() => {
                    setShowEyes(true)
                  }}
                  onMouseLeave={() => {
                    setShowEyes(false)
                  }}
                  onClick={() => {
                    cookies.set("gatsby-gdpr-google-analytics", true, {
                      path: "/",
                    })
                    cookies.set("gatsby-gdpr-facebook-pixel", true, {
                      path: "/",
                    })
                    cookies.set("gatsby-gdpr-google-tagmanager", true, {
                      path: "/",
                    })

                    setShowEyes(false)
                    setShowCookieBanner(false)
                    if (
                      process.env.NODE_ENV === "production" &&
                      typeof window !== "undefined"
                    ) {
                      initializeAndTrack(location)
                    }
                  }}
                >
                  Accept Cookies
                  <div className="eyes-wrapper">
                    <AnimatedEyes
                      image={eyeImage}
                      style={eyesAnimation}
                      alt=""
                    />
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </AnimatedCookieBar>
      )
  )
}
