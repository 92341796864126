import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"

import UpIcon from "./../assets/icons/up-arrow.svg"

const ScrollButton = styled(animated.button)`
  cursor: pointer;
  background-color: #d42b69;
  position: fixed;
  height: 40px;
  width: 40px;
  right: 0;
  bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  outline: none;
  z-index: 1;
  img {
    filter: brightness(0) invert(1);
  }
`

export default function ScrollToTop() {
  const [isScrolled, setIsScrolled] = useState(
    typeof document === "undefined"
      ? false
      : !!document.documentElement.scrollTop
  )

  const [isIconHovered, setIsIconHovered] = useState(false)

  useEffect(() => {
    if (typeof window === "undefined") return

    const onScroll = (e) => {
      setIsScrolled(!!e.target.documentElement.scrollTop)
    }

    window.addEventListener("scroll", onScroll)

    return () => window.removeEventListener("scroll", onScroll)
  }, [isScrolled])

  const slideInAnimation = useSpring({
    transform: isScrolled ? "translateX(-32px)" : "translateX(50px)",
  })

  useEffect(() => {
    if (!isIconHovered || typeof window === "undefined") return

    const timeoutId = window.setTimeout(() => {
      setIsIconHovered(false)
    }, 150)
    return () => {
      window.clearTimeout(timeoutId)
    }
  }, [isIconHovered])

  const upIconAnimation = useSpring({
    transform: isIconHovered ? "translateX(-4px)" : "translateY(0px)",
    config: {
      tension: 300,
      friction: 15,
    },
  })

  return (
    <ScrollButton
      onClick={() => {
        if (typeof window === "undefined") return

        window.scrollTo({ top: 0, behavior: "smooth" })
      }}
      onMouseEnter={() => {
        setIsIconHovered(true)
      }}
      style={slideInAnimation}
      aria-label="Scroll To Top"
    >
      <animated.div style={upIconAnimation}>
        <img src={UpIcon} alt="" />
      </animated.div>
    </ScrollButton>
  )
}
