import React, { useState } from "react"
import { Link } from "gatsby"
import { useSprings, animated } from "react-spring"
import styled from "styled-components"

import { breakpoints } from "./../assets/globalStyles"

const StyledDesktopNav = styled.nav`
  display: none;
  @media screen and (min-width: ${breakpoints.md}px) {
    display: flex;
    height: 100%;
  }
`

const TopBar = styled(animated.div)`
  background-color: white;
  height: 4px;
  margin-bottom: 32px;
`

const Wrapper = styled(Link)`
  margin-left: 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  font-family: Poppins;
  font-size: 18px;
  text-decoration: none;
  line-height: 48px;
  &:hover {
    color: #ffffff;
  }
`

export default function DesktopNav({ menuItems }) {
  const [hoveredIndex, setHoveredIndex] = useState()

  const topBarAnimations = useSprings(
    menuItems.length,
    menuItems.map(({ url }, index) => ({
      width:
        hoveredIndex === index ||
        (typeof window !== "undefined" &&
          window.location.pathname.includes(url))
          ? "100%"
          : "0%",
      config: {
        clamp: true,
        friction: 12,
      },
    }))
  )

  //combine animation properties with menu items
  const animatedMenuItems = menuItems.map((menuItem, index) => ({
    ...menuItem,
    animationProps: topBarAnimations[index],
  }))

  return (
    <StyledDesktopNav>
      {animatedMenuItems.map(({ label, url, animationProps }, index) => (
        <Wrapper
          onMouseEnter={() => {
            setHoveredIndex(index)
          }}
          onMouseLeave={() => {
            setHoveredIndex()
          }}
          key={url}
          to={url}
        >
          <TopBar style={animationProps} />

          {label}
        </Wrapper>
      ))}
    </StyledDesktopNav>
  )
}
