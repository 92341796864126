// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-cookies-js": () => import("./../../../src/pages/privacy-policy-cookies.js" /* webpackChunkName: "component---src-pages-privacy-policy-cookies-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-vacancies-js": () => import("./../../../src/pages/vacancies.js" /* webpackChunkName: "component---src-pages-vacancies-js" */),
  "component---src-templates-blog-archive-js": () => import("./../../../src/templates/blog-archive.js" /* webpackChunkName: "component---src-templates-blog-archive-js" */),
  "component---src-templates-case-studies-archive-js": () => import("./../../../src/templates/case-studies-archive.js" /* webpackChunkName: "component---src-templates-case-studies-archive-js" */),
  "component---src-templates-single-blog-js": () => import("./../../../src/templates/single-blog.js" /* webpackChunkName: "component---src-templates-single-blog-js" */),
  "component---src-templates-single-case-study-js": () => import("./../../../src/templates/single-case-study.js" /* webpackChunkName: "component---src-templates-single-case-study-js" */),
  "component---src-templates-single-service-js": () => import("./../../../src/templates/single-service.js" /* webpackChunkName: "component---src-templates-single-service-js" */),
  "component---src-templates-single-vacancy-js": () => import("./../../../src/templates/single-vacancy.js" /* webpackChunkName: "component---src-templates-single-vacancy-js" */)
}

