import React, { useEffect } from "react"

export default function CustomerChat() {
  useEffect(() => {
    setTimeout(() => {
      window.fbAsyncInit = function () {
        window.FB.init({
          xfbml: true,
          version: "v10.0",
        })
      }
      ;(function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src = "https://connect.facebook.net/en_GB/sdk/xfbml.customerchat.js"
        fjs.parentNode.insertBefore(js, fjs)
      })(document, "script", "facebook-jssdk")
    }, 3000)
  }, [])
  return (
    <>
      <div id="fb-root"></div>
      <div
        className="fb-customerchat"
        attribution="page_inbox"
        page_id="1627516797543614"
      ></div>
    </>
  )
}
